type LocaleOptions = 'pt-BR' | 'en-CA'
type CustomLocaleOptions = 'pt-BR' | 'pt-BR-full' | 'en-CA'

const options: Record<CustomLocaleOptions, Intl.DateTimeFormatOptions> = {
  'pt-BR': {
    day: '2-digit' as const,
    month: '2-digit' as const,
    year: 'numeric' as const,
  },
  'pt-BR-full': {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  },
  'en-CA': {
    year: 'numeric' as const,
    month: '2-digit' as const,
    day: '2-digit' as const,
  },
}

function getLocale(locale: CustomLocaleOptions): LocaleOptions {
  const customLocales: Record<string, LocaleOptions> = {
    'pt-BR-full': 'pt-BR',
  }

  if (Object.keys(customLocales).includes(locale))
    return customLocales[locale] as LocaleOptions

  return locale as LocaleOptions
}

/**
 * Formats a string or Date input into a locale-specific date string.
 *
 * - If the input is a YYYY-MM-DD string, we parse and format it.
 * - If the input is an invalid date, returns the original value.
 * - Otherwise, returns the parsed date as a localized string or the original input.
 *
 * @param input - Accepts a string, Date, or any type that might represent a date (e.g., ISO string).
 * @param locale - The locale used for formatting, defaults to 'pt-BR'.
 * @returns The formatted date string or the original input if it cannot be parsed.
 */
export default function formatDate(input: Date | string, locale: CustomLocaleOptions = 'pt-BR'): string | any {
  if (!input)
    return input

  let date: Date | null = null

  if (typeof input === 'string') {
    if (/^\d{4}-\d{2}-\d{2}$/.test(input)) {
      const [year, month, day] = input.split('-').map((str: string) => Number.parseInt(str, 10))

      date = new Date(year, month - 1, day)
    }
    else {
      date = new Date(input)
      date = new Date(date.getTime() + date.getTimezoneOffset() * 60000)
    }
  }
  else if (input instanceof Date) {
    date = input
  }

  if (!date || !date.getTime())
    return input

  return date.toLocaleDateString(getLocale(locale), options[locale])
}
